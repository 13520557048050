import React from "react"
import * as S from "./feature-blocks.styles"
import parse from "html-react-parser"
import FeatureBlock from "../../components/feature-block/feature-block.component"

const FeatureBlocks = ({ items, title }) => {
  if (!items) return null
  return (
    <S.Wrapper contained>
      {title && <h2 className="title">{parse(title)}</h2>}
      {items && (
        <div className="items">
          {items.map((item, index) => (
            <FeatureBlock item={item} key={index} className={item.size} />
          ))}
        </div>
      )}
    </S.Wrapper>
  )
}
export default FeatureBlocks
