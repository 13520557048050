import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  margin-bottom: 0;
  padding-bottom: 0;

  .title {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 400;

    sup {
      font-size: 0.6em;
      top: 0;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > .feature_block {
      // flex-basis: 63%;
      flex-basis: 100%;
      border-radius: 15px;
      padding: 0;
      overflow: hidden;
      border: 2px solid ${({ theme }) => theme.palette.primary.main};

      &.small {
        flex-basis: 32%;
      }

      &.large {
        flex-basis: 65%;
      }

      // &:nth-child(3n + 1) {
      //   flex-basis: 35%;
      // }
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      & > .feature_block {
        flex-basis: 100% !important;
      }
    }
  }
`
