import styled from "styled-components"

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  margin-bottom: 36px;

  & > .image {
    height: 30vw;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      height: auto;
    }
  }

  & > .content {
    padding: 20px 25px 10px;
    flex-grow: 1;
    text-align: left;

    .category {
      font-size: ${({ theme }) => theme.typography.pxToRem(14)};
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.fonts.primary};
      padding: 0;
      margin: 0;

      &::after {
        content: "";
        display: block;
        height: 2px;
        margin-top: 0.5rem;
        background: linear-gradient(to right, #799fdf 0, #50d9b5 59%, #80dc94);
      }
    }

    .title {
      font-size: ${({ theme }) => theme.typography.pxToRem(30)};
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-weight: 400;
      text-align: left;
      margin: 0;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
  height: 100%;

  .imageWrapper {
    height: 100%;
  }

  .gatsby-image-wrapper {
    img {
      border-radius: 15px 15px 0 0;
      object-fit: cover !important;
    }
  }
`
