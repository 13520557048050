import React from "react"
import * as S from "./feature-block.styles"
import parse from "html-react-parser"
import CustomImage from "../custom-image/custom-image.component"

const FeatureBlock = ({ item, key, className }) => {
  return (
    <S.CustomContainer className={"feature_block " + className} key={key}>
      <S.ImageContainer className="image">
        {item.image && (
          <a href={item.link.url} target={item.link.target}>
            <CustomImage
              className="imageWrapper"
              alt={item.image.altText}
              img={item.image}
            />
          </a>
        )}
      </S.ImageContainer>

      <div className="content">
        {item.category && <p className="category">{parse(item.category)}</p>}
        {item.title && (
          <h4 className="title">
            <a href={item.link.url} target={item.link.target}>
              {parse(item.title)}
            </a>
          </h4>
        )}
      </div>
    </S.CustomContainer>
  )
}

export default FeatureBlock
